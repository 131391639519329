export const DISPLAY_ALERT = 'SHOW_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'

export const SETUP_USER_BEGIN = 'SETUP_USER_BEGIN'
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS'
export const SETUP_USER_ERROR = 'SETUP_USER_ERROR'

export const LOGOUT_USER = 'LOGOUT_USER'

export const HANDLE_CHANGE = 'HANDLE_CHANGE'
export const CLEAR_VALUES = 'CLEAR_VALUES'

export const CREATE_RENT_BEGIN = 'CREATE_RENT_BEGIN'
export const CREATE_RENT_SUCCESS = 'CREATE_RENT_SUCCESS'
export const CREATE_RENT_ERROR = 'CREATE_RENT_ERROR'

export const GET_RENTS_BEGIN = 'GET_RENTS_BEGIN'
export const GET_RENTS_SUCCESS = 'GET_RENTS_SUCCESS'

export const GET_TROPHY_RENTS_BEGIN = 'GET_TROPHY_RENTS_BEGIN'
export const GET_TROPHY_RENTS_SUCCESS = 'GET_TROPHY_RENTS_SUCCESS'

export const SET_EDIT_RENT = 'SET_EDIT_RENT'

export const DELETE_RENT_BEGIN = 'DELETE_RENT_BEGIN'

export const EDIT_RENT_BEGIN = 'EDIT_RENT_BEGIN'
export const EDIT_RENT_SUCCESS = 'EDIT_RENT_SUCCESS'
export const EDIT_RENT_ERROR = 'EDIT_RENT_ERROR'

export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const CHANGE_PAGE = 'CHANGE_PAGE'
