import React from "react"
interface FormProps {
  type: string;
  name: string;
  value: string | number;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  labelText: string
}

const FormRow = ({ type, name, value, handleChange, labelText }: FormProps) => { 
  return (
    <div className='form-row'>
      <label htmlFor={name} className='form-label'>
        {labelText || name}
      </label>
      <input
        type={type}
        value={value}
        name={name}
        onChange={handleChange}
        className='form-input'
      />
    </div>
  )
}
  
export default FormRow  
