import React, { Component } from "react";
import { Link } from "react-router-dom";
import Canvas from "./Canvas.js";
import Register from "./Register";
import "./Home.scss";

interface Props {
  onClick: () => void
}

interface State {
  animation: boolean,
  isLogedIn: boolean
}


class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      animation: true,
      isLogedIn: false
    };
  }
    
  componentDidUpdate() {
    if (localStorage.getItem("user")) {
      // conditional to prevent infinite loop
      if (!this.state.isLogedIn) {
        this.setState({isLogedIn: true})
      }
    } else {
      if(this.state.isLogedIn) {
        this.setState({isLogedIn: false})
      }
    };
  }

  render() {
    const { isLogedIn } = this.state
    return (
      <div className='ps5-home'>
        <>
          <Canvas />
          <div className='ps5-home-content'>
            {!isLogedIn && (
              <div>
                <Register />
                <p>Nakon prijavljivanja, pritisni START</p>
              </div>
            )}
            <Link
              to='/rent'
              className='ps5-btn ps5-btn-mono ps5-btn-lg focus ps5-home-btn'
              onClick={() => this.props.onClick()}
            >
              <i className='material-icons'>power_settings_new</i>
              <div className='ps5-shine focus'></div>
              <div className='ps5-shine focus'></div>
              <div className='ps5-shine focus'></div>
            </Link>
          </div>
        </>
      </div>
    );
  }
}

export default Home;
