import React, { Component } from "react";
import Profile from "./Profile";
import AddRent from "./AddRent";
import Trophies from "./Trophies";
import "./Preview.scss";
import { TRent, TProfile, TTrophies, TPS } from "../../data";

type Props = {
  app: TRent | TProfile | TTrophies | TPS
}

class Preview extends Component<Props> {
  render() {

    const item = this.props.app;

    let label, title, text, renting, profile, trophies

    if ("label" in item) {
     label = item.label
    }
    if ("title" in item) {
      title = item.title
    }
    if ("text" in item) {
      text = item.text
    }
    if ("renting" in item) {
      renting = item.renting
    }
    if ("profile" in item) {
      profile = item.profile
    }
    if ("trophies" in item) {
      trophies = item.trophies
    }
   
    return (
      <div className='ps5-preview ps5-animate-from-bottom' key={label}>
        {title && <h2>{title}</h2>}

        {text && <p>{text}</p>}

        {renting && <AddRent />}

        {profile && <Profile />}

        {trophies && <Trophies />}
      </div>
    );
  }
}

export default Preview;
