import React, { Component } from "react";
import Disk from "./Disk";
import classNames from "classnames";
import "./Menu.scss";
import { TRent, TPS, TProfile, TTrophies } from "../../data";

interface Props {
  apps: (TRent | TPS | TProfile | TTrophies)[]
  appSelected: number
  onClick: (i: number) => void
}

class Menu extends Component<Props> {
  render() {
    const {apps, appSelected, onClick } = this.props;

    return apps ? (
      <ul
        className="ps5-menu"
        style={{ transform: "translateX(-" + appSelected * 80 + "px)" }}
      >
        {apps.map((item, i) => {
          return (
            <li key={i}>
              <Disk
                item={item}
                className={classNames({ "active focus": appSelected === i })} 
                onClick={() => onClick(i)}
              />
            </li>
          );
        })}
      </ul>
    ) : (
      false
    );
  }
}

export default Menu; 
