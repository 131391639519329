import React, { Component } from "react";
import { TRent, TProfile, TTrophies, TPS } from "../data";
import "./Background.scss";

const classNames = require("classnames");

interface Props {
  apps: (TRent | TPS | TProfile | TTrophies)[]
  appSelected: number
}

class Background extends Component<Props> {
  render() {
    const {apps, appSelected } = this.props;

    return apps ? (
      <div className="ps5-bg">
        {apps.map((item, i) => {
          return (
            <div
              key={i}
              style={{ backgroundImage: "url(" + item.bg + ")" }}
              className={classNames("ps5-bg-item", {
                active: appSelected === i,
              })}
            ></div>
          );
        })}
      </div>
    ) : (
      false
    );
  }
}

export default Background;
