import React from 'react';
import { useAppContext } from './context/appContext'
import { Navigate, useLocation } from 'react-router-dom'

interface Props {
  children: JSX.Element
}

const ProtectedRoute = ({ children }: Props) => {
  const location = useLocation();
  const { user } = useAppContext()

  if (!user && location.pathname !== '/') {
    return <Navigate to='/'/>
  }

  return children
}

export default ProtectedRoute 
