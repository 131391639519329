import React, { useState, useEffect, useRef, ChangeEvent, MouseEvent } from "react";
import { useAppContext } from "../../context/appContext";
import { useLocation } from "react-router-dom";
import { psPricing, projectorPricing, discount } from "./utils/pricing";
import { checkTrophy } from "./utils/checkTrophy";
import emailjs from "@emailjs/browser";
import Alert from "../Alert";
import FormRow from "../FormRow";
import FormRowSelect from "../FormRowSelect";
import TrophyAlert from "../TrophyAlert";
import "./AddRent.scss";

const AddRent = () => {
  const {
    showAlert,
    displayAlert,
    trophyType,
    console,
    days,
    daysOptionsPs4,
    daysOptionsPs5,
    controllers,
    controllersOptions,
    rentLocation,
    rentLocationOptions,
    projector,
    projectorOptions,
    phone,
    price,
    totalRents,
    handleChange,
    createRent,
    getRents,
    user,
  } = useAppContext();

  const location = useLocation().pathname;

  const [isTrophyActive, setIsTrophyActive] = useState<"active" | "">();

  const form = useRef<HTMLFormElement>(null);

  useEffect(() => {
    handleChange({ name: "console", value: location.split("/")[1] });
    handleChange({ name: "price", value: (psPricing(console, controllers, days) + projectorPricing(projector)) * discount(totalRents) });
    handleChange({ name: "trophyType", value: checkTrophy(totalRents).type });
    handleChange({ name: "trophyText", value: checkTrophy(totalRents).text });
  }, [console, controllers, days, location, projector]);


  const handleSubmit = (e: MouseEvent) => {
    e.preventDefault();

    if (!days || !controllers || !rentLocation || !phone) {
      displayAlert();
      return;
    } 

    // valid phone number format
    let regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

    if (!regex.test(phone)) {
      createRent();
      return;
    }

    if (trophyType !== "") {
      setIsTrophyActive("active");
    }
    setTimeout(() => {
      setIsTrophyActive("");
    }, 5000);

    createRent();

    // Send email notification to admin
    const templateParams = {
      email: user.email,
      name: user.name,
      console,
      controllers,
      days,
      rentLocation,
      projector,
      phone,
      price,
    };
    emailjs.send("service_2z0beii", "template_hcw1z0c", templateParams, "UDt6VBDiGKB9aIwhm").then(
      (response) => {
        window.console.log("SUCCESS!", response.status, response.text);
      },
      (err) => {
        window.console.log("FAILED...", err);
      }
    );
  };

  const handleRentInput = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };

  useEffect(() => {
    getRents();
  }, [days]);

  return (
    <>
      <TrophyAlert isTrophyActive={isTrophyActive} />
      <form ref={form} className='form-add-rent'>
        {showAlert && <Alert />}
        <div className='form-center'>
          <FormRowSelect
            name='days'
            labelText='Broj dana:'
            value={days}
            handleChange={handleRentInput}
            list={location === "/ps4" ? daysOptionsPs4 : daysOptionsPs5}
          />
          <FormRowSelect
            name='controllers'
            labelText='Broj džojstika:'
            value={controllers}
            handleChange={handleRentInput}
            list={controllersOptions}
          />
          <FormRowSelect
            name='rentLocation'
            labelText='Mesto:'
            value={rentLocation}
            handleChange={handleRentInput}
            list={rentLocationOptions}
          />
          <FormRowSelect
            name='projector'
            labelText='Sa projektorom:'
            value={projector}
            handleChange={handleRentInput}
            list={projectorOptions}
          />
          <FormRow
            type='text'
            labelText='Kontakt telefon:'
            name='phone'
            value={phone}
            handleChange={handleRentInput}
          />
          <div className='btn-container'>
            <h4>Iznos: {price} dinara</h4>
            <button
              type='submit'
              className='ps5-btn ps5-btn-lg ps5-btn-primary'
              onClick={handleSubmit}
            >
              Iznajmi
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default AddRent;
