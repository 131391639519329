import React, { useReducer, useContext } from "react";
import reducer from "./reducer";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  LOGOUT_USER,
  HANDLE_CHANGE,
  CLEAR_VALUES,
  CREATE_RENT_BEGIN,
  CREATE_RENT_SUCCESS,
  CREATE_RENT_ERROR,
  GET_RENTS_BEGIN,
  GET_RENTS_SUCCESS,
  GET_TROPHY_RENTS_BEGIN,
  GET_TROPHY_RENTS_SUCCESS,
} from "./actions";

// < ---------------------------------------- interfaces/types ---------------------------------------- >

// interface IStorage {
//   getItem(key: string): string | null;
//   setItem(key: string, value: string): void;
//   removeItem(key: string): void;
// }

type Props = {
  children: React.ReactNode;
};

type SetupUserResponse = {
  user: string;
  token: string;
};
interface Source {
  currentUser: object;
  endPoint: string;
  alertText: string;
}

// interface Response {
//   data: {
//     data: Array<Source>;
//   };
// }

// interface MyRequiredHeaders {
//   authorization: AxiosRequestHeaders;
// }
  

interface AppContextInterface {
  isLoading: boolean;
  showAlert: boolean;
  alertType: string;
  alertText: string;
  trophyType: string;
  trophyText: string;
  user: any;
  token: string | null;
  console: string;
  days: number | string;
  daysOptionsPs4: ("" | number)[];
  daysOptionsPs5: ("" | number)[];
  controllers: number | string;
  controllersOptions: ("" | number)[];
  rentLocation: string;
  rentLocationOptions: string[];
  projector: string;
  projectorOptions: string[];
  phone: string;
  price: number;
  rents: never[];
  trophyRents: never[];
  totalRents: number;
  totalSpent: number;
  sort: string;
  sortOptions: string[];
  displayAlert: () => void,
  setupUser: ({}) => void,
  logoutUser: () => void,
  handleChange: ({}) => void,
  clearValues: () => void,
  createRent: () => void,
  getRents: () => void,
  getTrophyRents: () => void,
}

// < ---------------------------------------- End of interfaces/types ---------------------------------------- >

const token = localStorage.getItem("token");
const user = localStorage.getItem("user");

const initialState = {
  isLoading: false,
  showAlert: false,
  alertType: "",
  alertText: "",
  trophyType: "",
  trophyText: "",
  user: user ? JSON.parse(user) : null,
  token: token,
  console: "",
  days: 0,
  daysOptionsPs4: ["", 1, 2, 3, 4, 5, 6, 7],
  daysOptionsPs5: ["", 1, 2, 3],
  controllers: 0,
  controllersOptions: ["", 2, 4],
  rentLocation: "",
  rentLocationOptions: ["", "Novi Sad", "Veternik", "Futog", "Sremska Kamenica", "Petrovaradin"],
  projector: "Ne",
  projectorOptions: ["Ne", "1 dan", "2 dana", "3 dana", "4 dana"],
  phone: "",
  price: 0,
  rents: [],
  trophyRents: [],
  totalRents: 0,
  totalSpent: 0,
  sort: "novije",
  sortOptions: ["novije", "starije", "cena niska", "cena visoka"],
};

const AppContext = React.createContext({} as AppContextInterface);

const AppProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // axios
  const authFetch = axios.create({
    baseURL: "/api/v1",
  });

  // request
  authFetch.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      config.headers = config.headers ?? {};
      // NOTE can't fix error
      // @ts-ignore 
      config.headers.common['Authorization'] = `Bearer ${state.token}`;
      return config;
    },
    (error: AxiosError): Promise<AxiosError> => {
      return Promise.reject(error);
    }
  );

  // response
  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError): Promise<AxiosError> => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
    }, 3000);
  };

  const displayAlert = () => {
    dispatch({ type: DISPLAY_ALERT });
    clearAlert();
  };

  const addUserToLocalStorage = ({ user, token }: { user: string; token: string }) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);
  };

  const removeUserFromLocalStorage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  const setupUser = async ({
    currentUser,
    endPoint,
    alertText,
  }: Source) => {
    dispatch({ type: SETUP_USER_BEGIN });
    try {
      const { data } = await axios.post<SetupUserResponse>(`/api/v1/auth/${endPoint}`, currentUser);
      const { user, token } = data;

      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: { user, token, alertText },
      });

      addUserToLocalStorage({ user, token });
    } catch (e: any) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: e.response.data.msg },
      });
    }
    clearAlert();
  };

  const logoutUser = () => {
    dispatch({ type: LOGOUT_USER });
    removeUserFromLocalStorage();
  };

  const handleChange = ({ name, value }: { name: any; value: any }) => {
    dispatch({ type: HANDLE_CHANGE, payload: { name, value } });
  };

  const clearValues = () => {
    dispatch({ type: CLEAR_VALUES });
  };

  const createRent = async () => {
    dispatch({ type: CREATE_RENT_BEGIN });
    try {
      const {
        console,
        days,
        controllers,
        rentLocation,
        projector,
        phone,
        price,
        trophyType,
        trophyText,
      } = state;
      await authFetch.post("/rents", {
        console,
        days,
        controllers,
        rentLocation,
        projector,
        phone,
        price,
        trophy: [trophyType, trophyText],
      });
      dispatch({ type: CREATE_RENT_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 401) return;        
      
      dispatch({
        type: CREATE_RENT_ERROR,
        payload: { msg: err.response?.data.msg },
      });
    }
    clearAlert();
  };

  const getRents = async () => {
    const { sort } = state;

    let url = `/rents?sort=${sort}`;
    dispatch({ type: GET_RENTS_BEGIN });
    try {
      const { data } = await authFetch(url);
      const { rents, totalRents, totalSpent } = data;
      dispatch({
        type: GET_RENTS_SUCCESS,
        payload: {
          rents,
          totalRents,
          totalSpent,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getTrophyRents = async () => {
    let url = "/trophies";
    dispatch({ type: GET_TROPHY_RENTS_BEGIN });
    try {
      const { data } = await authFetch(url);
      const { trophyRents } = data;
      dispatch({
        type: GET_TROPHY_RENTS_SUCCESS,
        payload: {
          trophyRents,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        displayAlert,
        setupUser,
        logoutUser,
        handleChange,
        clearValues,
        createRent,
        getRents,
        getTrophyRents,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
