export const checkTrophy = (totalRents: number) => {
  let type = "";
  let text = "";
    
  // check if next order should fire trophy alert
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  totalRents === 0 ? (type = "bronze", text = "Prva porudžbina") 
  : totalRents === 4 ? (type = "silver", text = "Peta porudžbina")
  : totalRents === 9 ? (type = "gold", text = "Deseta porudžbina")
  : totalRents === 10 ? (type = "platinum", text = "Preko 10 porudžbina")
  : {};
    
  return {type, text}    
} 
