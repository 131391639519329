import React, { ChangeEventHandler } from 'react';

interface Props {
  labelText: string
  name: string
  value: string | number
  handleChange: ChangeEventHandler<HTMLSelectElement>
  list: (string | number)[];
}


const FormRowSelect = ({ labelText, name, value, handleChange, list }: Props) => {
  return (
    <div className='form-row'>
      <label htmlFor={name} className='form-label'>
        {labelText || name}
      </label>     
      <select
        name={name}
        value={value}
        onChange={handleChange}
        className='form-select'
      >
        {list.map((itemValue: string | number, index: React.Key | null | undefined) => {
          return (
            <option key={index} value={itemValue}>
            {itemValue}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default FormRowSelect
