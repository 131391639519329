const consolePrices: Record<string,Record<string,Record<string,number>>> = {
  ps4: {
    2: {
      1: 1300,
      2: 2000,
      3: 2800,
      4: 3500,
      5: 4300,
      6: 5000,
      7: 5500
    },
    4: {
      1: 1500,
      2: 2300,
      3: 3300,
      4: 4000,
      5: 5000,
      6: 5700,
      7: 6500
    }
  },
  ps5: {
    2: {
      1: 2000,
      2: 3400,
      3: 4500
    },
    4: {
      1: 2400,
      2: 3800,
      3: 4900
    }
  }
};

const projectorPrices: Record<string,number> = {
  "1 dan": 1500,
  "2 dana": 2500,
  "3 dana": 3300,
  "4 dana": 3900
};

export const psPricing = (console: string | number, controllers: string | number, days: string | number) => {
  if (!console || !controllers || !days) {
    return 0;
  }
  return consolePrices[console][controllers][days];
};

export const projectorPricing = (projector: string) => projectorPrices[projector] || 0;  

export const discount = (totalRents: number): number => {
  let discount;

  totalRents === 1
    ? (discount = 0.8)
    : totalRents === 5
    ? (discount = 0.5)
    : totalRents === 10
    ? (discount = 0)
    : totalRents > 10
    ? (discount = 0.9)
    : (discount = 1);

  return discount;
};