import React, { Component } from "react";
import { Link } from "react-router-dom";
import { TRent, TPS, TProfile, TTrophies } from "../../data";
import Avatar from "./Avatar";
import "./Disk.scss";

const classNames = require("classnames");

interface Props {
  item: TRent | TPS | TProfile | TTrophies
  className: string
  onClick: React.MouseEventHandler

}

class Disk extends Component<Props> {
  render() {
    const {item, className, onClick} = this.props; 
    const { href, cover } = item
    let label 
    let profile
    if ("label" in item) {
      label = item.label
    }
    if ("profile" in item) {
      profile = item.profile
    }

    return ( 
      <Link
        to={href}
        title={label}
        className={classNames(
          "ps5-disk",
          className
        )}
        style={{ backgroundImage: `url(${cover})` }}
        onClick={onClick}
      >
        <div>
          {label && <h3>{label}</h3>}
          {profile && <Avatar />}
        </div>
      </Link>
    );
  }
}

export default Disk;
  