import React, { Component } from "react";
import "./Modal.scss";

interface Props {
  modalContent: JSX.Element | ""
  onClick: () => void
}

class Modal extends Component<Props> {  
  render() {
    const { modalContent, onClick } = this.props

    return (
      <div className={"ps5-modal"} onClick={() => onClick()}>
        <div className="ps5-modal-wrapper" onClick={(e) => e.stopPropagation()}>
          {modalContent}
          <a
            href="#modal-close"
            title="Zatvori"
            className="ps5-btn ps5-btn-mono ps5-modal-btn"
            onClick={() => onClick()}
          >
            <i className="material-icons">close</i>
          </a>
        </div>
      </div>
    );
  }
}

export default Modal;
