import React from "react";
import * as ReactDOMClient from 'react-dom/client';
import { AppProvider } from './context/appContext';
import App from "./App";

const rootElement = document.getElementById('root');

const root = ReactDOMClient.createRoot(rootElement as Element);

root.render(
  <React.StrictMode>
    <AppProvider>       
      <App />
    </AppProvider>
  </React.StrictMode>
)
